import { OnboardingTooltip, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import { usePromoSearchTypesOnboarding } from './hooks/usePromoSearchTypesOnboarding';
import { NO_BREAK_SPACE } from '../../constants/symbolsUnicode';

export const PromoSearchTypesOnboardingContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { open, closeHandler } = usePromoSearchTypesOnboarding();

  return (
    <OnboardingTooltip
      theme="black"
      content={
        <UIText2 color="text-inverted-default" whiteSpace="pre-line">
          Добавили новые фильтры{NO_BREAK_SPACE}— для{NO_BREAK_SPACE}поиска выгодной ипотеки и{NO_BREAK_SPACE}скидок от
          {NO_BREAK_SPACE}застройщиков
        </UIText2>
      }
      placement="top-start"
      portal={false}
      flip={false}
      open={open}
      onClose={closeHandler}
    >
      <span>{children}</span>
    </OnboardingTooltip>
  );
};
