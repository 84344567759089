import * as React from 'react';

import { IconActionChevronRight12, IconActionChevronLeft12 } from '@cian/ui-kit-design-tokens/icons';
import { mergeStyles } from '@cian/utils';
import { throttle } from '@cian/newbuilding-utils';
import * as styles from './Carousel.css';
import { clsx } from 'clsx';

export interface ISlideProps {
  key: number;
  visible: boolean;
}
type TSlide = (props: ISlideProps) => JSX.Element | undefined | null | false;

export interface ICarouselProps {
  slides: TSlide[];
  defaultSlideIndex?: number;
  dataMark?: string;
  isPromo?: boolean;
}

interface ICarouselState {
  currentIndex: number;
  hiddenSlide?: null | number;
}

export class Carousel extends React.PureComponent<ICarouselProps, ICarouselState> {
  private constructor(props: ICarouselProps) {
    super(props);
    const { defaultSlideIndex = 0, slides } = this.props;
    const isCorrectIndex = defaultSlideIndex > -1 && defaultSlideIndex < slides.length;

    this.state = {
      currentIndex: isCorrectIndex ? defaultSlideIndex : 0,
    };
  }

  public setSlide(slideNumber: number) {
    this.setState({ currentIndex: slideNumber });
  }

  public render() {
    const { slides, dataMark = 'Carousel', isPromo } = this.props;
    const { currentIndex, hiddenSlide } = this.state;

    return (
      <div className={clsx(styles['carousel'], isPromo && styles['carousel--promo'])} data-mark={dataMark}>
        <button
          onClick={this.prevSlide}
          {...mergeStyles([
            styles['carousel-left_arrow'],
            !this.canSlideLeft() && styles['carousel-left_arrow--disabled'],
          ])}
          aria-label="Предыдущее изображение"
          name="Предыдущее изображение"
          data-mark="CarouselLeftArrow"
        >
          <div className={styles['carousel-icon']}>
            <IconActionChevronLeft12 color={isPromo ? 'gray60_100' : 'icon-inverted-default'} />
          </div>
        </button>
        <div {...mergeStyles([styles['carousel-stack']])}>
          {slides.map((slide, index) => (
            <div
              key={index}
              {...mergeStyles([
                styles['carousel-slide'],
                hiddenSlide === index && styles['carousel-slide--hide'],
                this.isLeftSlide(index) && styles['carousel-slide--left'],
                index === currentIndex && styles['carousel-slide--current'],
                this.isRightSlide(index) && styles['carousel-slide--right'],
              ])}
              data-mark="CarouselSlide"
            >
              {slide({
                key: index,
                visible: this.isVisibleSlide(index),
              })}
            </div>
          ))}
        </div>
        <button
          onClick={this.nextSlide}
          {...mergeStyles([
            styles['carousel-right_arrow'],
            !this.canSlideRight() && styles['carousel-right_arrow--disabled'],
          ])}
          aria-label="Следующие изображение"
          name="Следующие изображение"
          data-mark="CarouselRightArrow"
        >
          <div className={styles['carousel-icon']}>
            <IconActionChevronRight12 color={isPromo ? 'gray60_100' : 'icon-inverted-default'} />
          </div>
        </button>
        {slides && slides.length > 1 ? (
          <div className={styles['carousel-counter']}>{`${this.state.currentIndex + 1}/${slides.length}`}</div>
        ) : null}
      </div>
    );
  }

  private getNewIndex = (wantIndex: number, maxIndex: number) => {
    let newIndex = wantIndex;
    if (wantIndex < 0) {
      newIndex = maxIndex;
    } else if (wantIndex > maxIndex) {
      newIndex = 0;
    }

    return newIndex;
  };

  private changeCurrentIndex = (increment: number) =>
    throttle(300, () => {
      const { currentIndex } = this.state;
      const maxIndex = this.getMaxSlideIndex();
      const newIndex = this.getNewIndex(currentIndex + increment, maxIndex);
      let hideSlideIndex = null;
      if (this.props.slides.length === 3) {
        hideSlideIndex = this.getNewIndex(newIndex + increment, maxIndex);
      }
      this.setState({
        currentIndex: newIndex,
        hiddenSlide: hideSlideIndex,
      });
    });

  private nextSlide = this.changeCurrentIndex(1);

  private prevSlide = this.changeCurrentIndex(-1);

  private isLeftSlide = (index: number) => {
    let stepIndex = this.state.currentIndex - 1;
    if (this.isBigStack()) {
      if (stepIndex < 0) {
        stepIndex = this.getMaxSlideIndex();
      }

      return index === stepIndex;
    }

    return index === stepIndex && this.state.currentIndex > stepIndex;
  };

  private isRightSlide = (index: number) => {
    let stepIndex = this.state.currentIndex + 1;
    if (this.isBigStack()) {
      if (stepIndex > this.getMaxSlideIndex()) {
        stepIndex = 0;
      }

      return index === stepIndex;
    }

    return index === stepIndex && this.state.currentIndex < stepIndex;
  };

  private isBigStack = () => this.props.slides.length > 2;

  private getMaxSlideIndex = () => this.props.slides.length - 1;

  private canSlide = () => this.props.slides.length > 1;

  private canSlideLeft = () => this.canSlide() && (this.isBigStack() || this.state.currentIndex !== 0);

  private canSlideRight = () => {
    const currentSlidIsLast = this.state.currentIndex === this.props.slides.length - 1;

    return this.canSlide() && (this.isBigStack() || !currentSlidIsLast);
  };

  private isVisibleSlide = (index: number) => {
    const { currentIndex } = this.state;
    const relativeIndex = index - currentIndex;
    const visible =
      this.isSlideSibling(this.getMaxSlideIndex() - Math.abs(relativeIndex)) || this.isSlideSibling(relativeIndex);

    return visible;
  };

  private isSlideSibling = (relativeIndex: number) => relativeIndex >= -2 && relativeIndex <= 2;
}
