import * as React from 'react';

import { TooltipDesktop } from '@cian/ui-kit/tooltip';
import { Link } from '../Link';

import * as styles from './NewbuildingFeatures.css';

import {
  IconProduct3DTour16,
  IconProductBuilding16,
  IconCommunicationComment16,
  IconFunctionalCalendar16,
  IconMediaVideo16,
  IconProductMainPlan16,
} from '@cian/ui-kit-design-tokens/icons';

interface INewbuildingFeatureProps {
  url: string;
  title: string;
  dataMark?: string;
  type: 'hasProgressOfConstructions' | 'reviews' | 'hasMvk' | 'has3dTour' | 'hasFlatTourBooking' | 'hasVideo';
  onClick?(type: string): void;
}

const assetMap: { [index: string]: React.ReactNode } = {
  hasProgressOfConstructions: <IconProductBuilding16 color={'icon-inverted-default'} />,
  reviews: <IconCommunicationComment16 color={'icon-inverted-default'} />,
  hasMvk: <IconProductMainPlan16 color={'icon-inverted-default'} />,
  has3dTour: <IconProduct3DTour16 color={'icon-inverted-default'} />,
  hasFlatTourBooking: <IconFunctionalCalendar16 color={'icon-inverted-default'} />,
  hasVideo: <IconMediaVideo16 color={'icon-inverted-default'} />,
};

export function NewbuildingFeature(props: INewbuildingFeatureProps) {
  const { url, title, type, onClick } = props;
  const icon = assetMap[type];

  const handleClickFeature = React.useCallback(() => {
    /* istanbul ignore else */
    if (onClick) {
      onClick(type);
    }
  }, [type, onClick]);

  return (
    <div className={styles['feature-container']} onClick={handleClickFeature} data-testid="newbuilding-feature">
      <TooltipDesktop title={title} placement="bottom">
        <Link target="blank" url={url} linkStyle={styles['feature']}>
          <div className={styles['feature-icon-wrapper']}>{icon}</div>
        </Link>
      </TooltipDesktop>
    </div>
  );
}
