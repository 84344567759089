import * as React from 'react';
import { useSelector } from 'react-redux';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';

import { trackBannerClick, trackBannerShown } from './tracking';
import { FlightBanner } from '../../components/FlightBanner';
import { getCurrentJsonQuery } from '../../selectors/location';
import { getNewbuildingFlightEntryUrl } from '../../selectors/newbuildingFlight';

export const FlightBannerContainer: React.FC = () => {
  const jsonQuery = useSelector(getCurrentJsonQuery);
  const url = useSelector(getNewbuildingFlightEntryUrl) as string;

  const handleBannerClick = React.useCallback(() => trackBannerClick(jsonQuery), [jsonQuery]);
  const handleBannerShown = React.useCallback(() => trackBannerShown(jsonQuery), [jsonQuery]);

  return (
    <ActionAfterViewObserver callback={handleBannerShown} triggerOnce>
      <FlightBanner url={url} onBannerClick={handleBannerClick} />
    </ActionAfterViewObserver>
  );
};
