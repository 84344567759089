/* eslint-disable react/forbid-elements */
import * as React from 'react';
import * as styles from './Preloader.css';

export interface IPreloaderProps {
  size: number;
}

export const Preloader: React.FC<IPreloaderProps> = ({ size }) => {
  const gradientId = `preloader-gradient-${styles['preloader']}`;

  return (
    <svg className={styles['preloader']} width={size} height={size} viewBox="0 0 40 40">
      <defs>
        <linearGradient id={gradientId} gradientUnits="userSpaceOnUse" y1="30" x2="40" y2="30">
          <stop offset="0" stopColor="#0076eb"></stop>
          <stop offset="1" stopColor="var(--decorative-theme-white)" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
      <path
        fill={`url(#${gradientId})`}
        d="M20,35c-8.3,0-15-6.7-15-15H0c0,11,9,20,20,20c11,0,20-9,20-20h-5C35,28.3,28.3,35,20,35z"
      ></path>
    </svg>
  );
};
