import * as React from 'react';
import { Label, LinkButton } from '@cian/ui-kit';
import { PictogramProductWarningSymbol40 } from '@cian/ui-kit-design-tokens/pictograms';

import { trackProblemIconHover, trackProblemMoreButtonClick } from '../../actions/analytics';
import { SIZE_20, SIZE_24, SIZE_28, SIZE_360, SIZE_4, SIZE_WIDE } from '../../constants/sizes';
import { Container } from '../Container';
import { ClickOutside } from '../outside';
import { MountPopup } from '../PopupViewer';
import { Text } from '../Text';

export interface IProblemNewbuildingPopupProps {
  url: string;
  GKId: number;
}

interface IProblemNewbuildingPopupState {
  isOpen: boolean;
}

export class ProblemNewbuildingPopup extends React.PureComponent<
  IProblemNewbuildingPopupProps,
  IProblemNewbuildingPopupState
> {
  public state: Readonly<IProblemNewbuildingPopupState> = {
    isOpen: false,
  };

  private onIconHover = () => {
    const { GKId } = this.props;
    trackProblemIconHover(GKId);
  };

  private onMoreButtonClick = () => {
    const { GKId } = this.props;
    trackProblemMoreButtonClick(GKId);
  };

  public render() {
    const { isOpen } = this.state;
    const url = `${this.props.url}#problem`;

    return (
      <span
        onMouseEnter={this.handleToggle(true)}
        onMouseLeave={this.handleToggle(false)}
        onClick={this.handleToggle(true)}
      >
        <MountPopup
          placement="bottom"
          padding={-20}
          component={
            isOpen && (
              <ClickOutside onOutside={this.handleToggle(false)}>
                <div onMouseLeave={this.handleToggle(false)}>
                  <Container padding={`${SIZE_28} 0 0`}>
                    <Container
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      shadow
                      borderColor="gray_0"
                      borderStyle="solid"
                      backgroundColor="white"
                      maxWidth={SIZE_WIDE}
                      width={SIZE_360}
                      padding={`${SIZE_24} 0`}
                    >
                      <PictogramProductWarningSymbol40 color={'control-negative-primary-pressed'} />
                      <Container padding={`${SIZE_20} ${SIZE_4} ${SIZE_4}`}>
                        <Text fontSize={16} fontWeight="bold">
                          Будьте внимательны
                        </Text>
                      </Container>
                      <Text fontSize={14}>В ЖК есть проблемные корпуса.</Text>
                      <Container padding={`${SIZE_20} 0 0`}>
                        <LinkButton
                          onClick={this.onMoreButtonClick}
                          href={url}
                          target="_blank"
                          theme="fill_secondary"
                          size="XS"
                        >
                          Узнать подробнее
                        </LinkButton>
                      </Container>
                    </Container>
                  </Container>
                </div>
              </ClickOutside>
            )
          }
        >
          <Container onMouseEnter={this.onIconHover}>
            <Label background="surface-negative-default" color="decorative-theme-red">
              Будьте внимательны
            </Label>
          </Container>
        </MountPopup>
      </span>
    );
  }

  private handleToggle = (value?: boolean) => () => {
    const { isOpen } = this.state;

    this.setState({ isOpen: value || !isOpen });
  };
}
