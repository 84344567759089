// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TGetNewbuildingsCountForSerpRequest,
  TGetNewbuildingsCountForSerpModel,
  IMappers,
  TGetNewbuildingsCountForSerpResponse,
  TGetNewbuildingsCountForSerpResponse_1,
  TGetNewbuildingsCountForSerpResponseError,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';

export const defaultConfig: TGetNewbuildingsCountForSerpModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'newbuilding-search',
  pathApi: '/v1/get-newbuildings-count-for-serp/',
  requestType: 'json',
} as TGetNewbuildingsCountForSerpModel;

export function createGetNewbuildingsCountForSerpModel(
  parameters: TGetNewbuildingsCountForSerpRequest,
): TGetNewbuildingsCountForSerpModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetNewbuildingsCountForSerpOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: TGetNewbuildingsCountForSerpRequest;
}

export async function fetchGetNewbuildingsCountForSerp<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetNewbuildingsCountForSerpOptions<TResponse200, TResponse400>): Promise<
  TGetNewbuildingsCountForSerpResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createGetNewbuildingsCountForSerpModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as TGetNewbuildingsCountForSerpResponse_1);
    }
    if (statusCode === 400) {
      return mappers[400](response as TGetNewbuildingsCountForSerpResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetNewbuildingsCountForSerpResponse;
}
