import { TColors } from '@cian/ui-kit-design-tokens/colors';

import { ESalesType } from '../../../../types/offersData';

export const getTitleColor = (salesType: ESalesType): TColors => {
  switch (salesType) {
    case ESalesType.BlackFriday:
      return 'decorative-theme-white';
    default:
      return 'decorative-theme-dark';
  }
};

export const getDescriptionColor = (salesType: ESalesType): TColors => {
  switch (salesType) {
    case ESalesType.BlackFriday:
      return 'decorative-theme-white';
    default:
      return 'text-secondary-default';
  }
};
