// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */

export interface INewbuildingLabelSchema {
  /** Текст **/
  text: string;
  /** Тип **/
  type: EType;
}

export enum EType {
  /** Акция черной пятницы **/
  'BlackFridayPromo' = 'blackFridayPromo',
  /** Льготная ипотека **/
  'DiscountMortgage' = 'discountMortgage',
  /** Эксклюзивная акция **/
  'PromoExclusive' = 'promoExclusive',
}
