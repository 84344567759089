import * as React from 'react';
import { Avatar, Button, IconButton, Image, Label, UIHeading3, UIText2, UIText3 } from '@cian/ui-kit';
import { IconActionClose16 } from '@cian/ui-kit-design-tokens/icons';
import { ScrollFade } from '@cian/adfox-templates';
import { Transport } from '../Transport';
import { useBooleanState } from '@cian/react-utils';
import * as styles from './TgbNativedescGeneral.css';
import { TransportProps } from '../Transport/Transport';

type Transport = TransportProps & {
  id: number;
};

export type TgbNativedescGeneralProps = {
  url: string;
  image: string;
  logoImageUrl: string | undefined;
  title: string;
  description: string;
  address: string;
  builderName: string;
  annotation: string;
  button: string | undefined;
  label: string | undefined;
  transports: Transport[];
};

export const TgbNativedescGeneral: React.FC<TgbNativedescGeneralProps> = props => {
  const { title, address, description, annotation, image, url, button, label, logoImageUrl, builderName, transports } =
    props;

  const { state: isClosed, setTrue: setClosed } = useBooleanState();

  const handleClose = React.useCallback<(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void>(
    event => {
      event.preventDefault();

      setClosed();
    },
    [setClosed],
  );

  if (isClosed) {
    return <div className={styles['empty']} data-testid="empty_native_adfox_block" />;
  }

  return (
    <a href={url} target="_blank" className={styles['container']} rel="noopener noreferrer">
      <div className={styles['photo-block']}>
        {!!label && (
          <div className={styles['photo-label']}>
            <Label color="decorative-theme-white" background="overlay-default">
              {label}
            </Label>
          </div>
        )}
        <Image src={image} objectFit="cover" />
      </div>
      <div className={styles['main-content-block']}>
        <div className={styles['content-container']}>
          <UIHeading3 color="text-main-default">{title}</UIHeading3>
          <div className={styles['address-container']}>
            {!!transports.length && (
              <div className={styles['transports-container']}>
                {transports.map(transport => (
                  <Transport
                    key={transport.id}
                    name={transport.name}
                    remoteness={transport.remoteness}
                    iconColor={transport.iconColor}
                    iconType={transport.iconType}
                  />
                ))}
              </div>
            )}
            <UIText2 data-testid="address" color="gray60_100">
              {address}
            </UIText2>
          </div>
          <div className={styles['description-wrapper']}>
            <UIText2 as="p">{description}</UIText2>
          </div>
        </div>
        <div>
          <Button data-testid="more-about-jk-button" fullWidth={false} theme="fill_primary" size="XS">
            {button || 'Подробнее о ЖК'}
          </Button>
        </div>
      </div>
      <div className={styles['publisher-block']}>
        <Avatar size={80} src={logoImageUrl} type="builder" />
        <div className={styles['builder-name-wrapper']}>
          <UIText2 data-testid="builderName">{builderName}</UIText2>
        </div>
        <div className={styles['ad-info']}>
          <UIText3 color="gray40_100">{annotation}</UIText3>
          <ScrollFade size={12} />
        </div>
      </div>
      <div className={styles['close-block']} data-testid="close-block">
        <div>
          <IconButton data-testid="close-button" icon={<IconActionClose16 />} onClick={handleClose} />
        </div>
      </div>
    </a>
  );
};
