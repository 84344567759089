import { Label, UIText3 } from '@cian/ui-kit';
import { TColors } from '@cian/ui-kit-design-tokens/colors';
import { IconProductHotLeads16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { Container } from '../Container';
import { SIZE_8 } from '../../constants/sizes';
import { ELabelType, ICardLabel } from '../../types/newbuilding';
import { ProblemNewbuildingPopup } from '../ProblemNewbuildingPopup';

interface ICardLabelListProps {
  labels: ICardLabel[] | null;
  hasProblem: boolean;
  url: string;
  id: number;
}

const discountMortgage = require('../../assets/discount-mortgage.svg');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LABEL_TO_ICON: Record<ELabelType, React.ReactElement<any, any> | undefined> = {
  [ELabelType.PromoExclusive]: undefined,
  [ELabelType.BlackFridayPromo]: <IconProductHotLeads16 color="icon-main-default" />,
  [ELabelType.DiscountMortgage]: <img src={discountMortgage} alt="" />,
};

const LABEL_TO_BACKGROUND_COLOR: Record<ELabelType, TColors> = {
  [ELabelType.PromoExclusive]: 'linear-gradient(90deg, black 0%, #0766fc 100%)' as TColors,
  [ELabelType.BlackFridayPromo]: 'surface-main-default',
  [ELabelType.DiscountMortgage]: 'rgba(135, 41, 255, 0.10)' as TColors,
};

const LABEL_TO_COLOR: Record<ELabelType, TColors> = {
  [ELabelType.PromoExclusive]: 'decorative-theme-white',
  [ELabelType.BlackFridayPromo]: 'accent-main-primary',
  [ELabelType.DiscountMortgage]: 'decorative-accent-purple',
};

export const CardLabelList: React.FC<ICardLabelListProps> = ({ labels, hasProblem, url, id }) => {
  const showLabels = React.useMemo(() => {
    const hasLabels = labels && labels.length > 0;

    return hasLabels || hasProblem;
  }, [labels, hasProblem]);

  if (!showLabels) {
    return null;
  }

  return (
    <Container display="flex" columnGap="8px" alignItems="center" margin={`0 0 ${SIZE_8}`}>
      {hasProblem && <ProblemNewbuildingPopup url={url} GKId={id} />}
      {labels &&
        labels.map((label, index) => {
          const background = LABEL_TO_BACKGROUND_COLOR[label.type];
          const color = LABEL_TO_COLOR[label.type];
          const icon = LABEL_TO_ICON[label.type];

          return (
            <UIText3 key={index}>
              <Label icon={icon} background={background} color={color}>
                {label.text}
              </Label>
            </UIText3>
          );
        })}
    </Container>
  );
};
