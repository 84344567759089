import * as React from 'react';
import { useSelector } from 'react-redux';

import { isDiscountMortgageBannerEnabled } from '../../selectors/discountMortgage';
import { getNewbuildingFlightBannerAvailable } from '../../selectors/newbuildingFlight';
import { DiscountMortgageBannerContainer } from '../DiscountMortgageBannerContainer';
import { FlightBannerContainer } from '../FlightBannerContainer';
import { NewbuildingConsultBannerContainer } from '../NewbuildingConsultBanner';

export const BannerContainer = () => {
  const isNewbuildingFlightBannerAvailable = useSelector(getNewbuildingFlightBannerAvailable);
  const isDiscountMortgageBannerAvailable = useSelector(isDiscountMortgageBannerEnabled);

  if (isNewbuildingFlightBannerAvailable) {
    return <FlightBannerContainer />;
  }

  if (isDiscountMortgageBannerAvailable) {
    return <DiscountMortgageBannerContainer />;
  }

  return <NewbuildingConsultBannerContainer />;
};
