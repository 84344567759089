import * as React from 'react';
import { connect, useSelector } from 'react-redux';

import { Container } from '../../components/Container';

import { IApplicationState } from '../../types/redux';

import { OffersBodyContainer } from './OffersBodyContainer';
import { OffersFooterContainer } from './OffersFooterContainer';
import { SimilarNewbuildings } from './SimilarNewbuildings';
import { INewbuilding, INewbuildingPromoSnippet } from '../../types/newbuilding';
import { SearchPreloader } from '../../components/SearchPreloader';
import { EmptySearchResult } from '../../components/EmptySearchResult';
import { PromoSnippetContainer } from '../PromoSnippetContainer';
import {
  getIsNewbuildingPromoSnippetEnabled,
  getNewbuildingPromoSnippet,
} from '../../selectors/newbuildingPromoSnippet';
import { ISeo } from '../../types/offersData';
import { compose } from 'ramda';
import { IVisitedOffersContext, withVisitedOffersContext } from '@cian/frontend-visited-offers-widget';
import { IProduct } from '../../types/analytics';
import { getUser } from '../../selectors/user';
import { trackPageViewCollect } from './tracking';

interface IOffersStoreProps {
  newbuildings: INewbuilding[];
  newbuildingPromoSnippet?: INewbuildingPromoSnippet | null;
  isPreloaderVisible: boolean;
  isNewbuildingPromoSnippetEnabled: boolean;
  isNewbuildingPromoSnippetDisplayed: boolean;
  children?: React.ReactNode;
  seo: ISeo | null;
  hasContent: boolean;
}

interface IOwnProps {
  isOfferVisited(offerId: number): boolean;
  isLoaded: boolean;
}

type TOffersProps = IOffersStoreProps & IOwnProps;

export const Offers: React.FunctionComponent<TOffersProps> = props => {
  const {
    newbuildings = [],
    isPreloaderVisible,
    children,
    newbuildingPromoSnippet,
    isNewbuildingPromoSnippetEnabled,
    isNewbuildingPromoSnippetDisplayed,
    seo,
    isLoaded: isVisitedOffersLoaded,
    isOfferVisited,
    hasContent,
  } = props;

  const user = useSelector(getUser);

  const isSnippetDisplayed = isNewbuildingPromoSnippetEnabled && isNewbuildingPromoSnippetDisplayed;

  React.useEffect(() => {
    if (isVisitedOffersLoaded) {
      const products: IProduct[] = [];

      if (newbuildingPromoSnippet && isSnippetDisplayed) {
        products.push({
          id: newbuildingPromoSnippet.newbuilding.id,
          offerType: 'JK' as const,
          position: 1,
          extra: isOfferVisited(newbuildingPromoSnippet.newbuilding.id) ? { is_viewes: true } : undefined,
        });
      }

      const result = products.concat(
        newbuildings.map(({ id }, index) => ({
          id,
          offerType: 'JK' as const,
          position: products.length + index + 1,
          extra: isOfferVisited(id) ? { is_viewes: true } : undefined,
        })),
      );

      trackPageViewCollect({ user, products: result });
    }
  }, [isOfferVisited, isSnippetDisplayed, isVisitedOffersLoaded, newbuildingPromoSnippet, newbuildings, user]);

  return (
    <>
      {!!newbuildings.length && (
        <Container>
          {newbuildingPromoSnippet && isSnippetDisplayed && (
            <PromoSnippetContainer
              extensionFields={newbuildingPromoSnippet.extensionFields}
              newbuilding={newbuildingPromoSnippet.newbuilding}
            />
          )}
          <OffersBodyContainer
            newbuildings={newbuildings}
            isPromoNewbuildingDisplayed={isSnippetDisplayed}
            promoNewbuilding={newbuildingPromoSnippet?.newbuilding}
            aggregateRating={seo?.aggregateRating}
            reviewsCount={seo?.reviewsCount}
          />
          {children}
          <OffersFooterContainer />
        </Container>
      )}
      {!hasContent && <EmptySearchResult />}
      <SimilarNewbuildings hasNewbuildings={!!newbuildings.length} />
      {isPreloaderVisible && <SearchPreloader />}
    </>
  );
};

export function mapStateToProps(state: IApplicationState): IOffersStoreProps {
  return {
    newbuildings: state.offersData.newbuildings,
    hasContent: state.offersData.newbuildings.length > 0 || state.offersData.similarNewbuildings.length > 0,
    isPreloaderVisible: state.preloader.isVisible,
    newbuildingPromoSnippet: getNewbuildingPromoSnippet(state),
    isNewbuildingPromoSnippetEnabled: getIsNewbuildingPromoSnippetEnabled(state),
    isNewbuildingPromoSnippetDisplayed: state.config.isPromoSnippetDisplayed,
    seo: state.offersData?.seo,
  };
}

export function mapContextToProps({ isOfferVisited, isLoaded }: IVisitedOffersContext) {
  return {
    isOfferVisited,
    isLoaded,
  };
}

export const OffersContainer = compose(withVisitedOffersContext(mapContextToProps), connect(mapStateToProps))(Offers);
