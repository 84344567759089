import * as React from 'react';
import { UIHeading2 } from '@cian/ui-kit';
import { UIText1 } from '@cian/ui-kit/typography';
import * as style from './style.css';

export const EmptySearchResult = () => {
  return (
    <div className={style['container']} data-testid="EmptySearchResult">
      <div className={style['image']}>
        <div className={style['logo']} />
      </div>
      <div className={style['controls']}>
        <div className={style['description']}>
          <UIHeading2>Таких ЖК пока нет</UIHeading2>
          <UIText1>Попробуйте изменить фильтры</UIText1>
        </div>
      </div>
    </div>
  );
};
