import * as React from 'react';

import * as styles from './FlightBanner.css';
import { ArticleHeading1, ArticleParagraph1, Button, Theme } from '@cian/ui-kit';

interface IFlightBannerProps {
  url: string;
  onBannerClick: () => void;
}

export const FlightBanner: React.FC<IFlightBannerProps> = ({ url, onBannerClick }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className={styles['container']}
      data-testid="FlightBanner"
      onClick={onBannerClick}
    >
      <ArticleHeading1 color="text-inverted-default">12 способов купить квартиру выгодно</ArticleHeading1>
      <div className={styles['description-wrapper']}>
        <ArticleParagraph1 color="text-inverted-default">
          Скидки, доступная ипотека и рассрочки от застройщиков
        </ArticleParagraph1>
      </div>
      <div>
        <Theme theme="colored">
          <Button>Выбрать</Button>
        </Theme>
      </div>
    </a>
  );
};
