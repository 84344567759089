import { getCachedGeocode } from '../../../api/geo/get-cached-geocode';
import { getGeocodedSearchData } from '../../../api/geo/get-geocoded-search-data';
import { TApiGeoGeocodeCachedResponse_1 } from '../../../repositories/monolith-cian-realty/api/geo/geocode-cached';
import { IApiGeoGeocodedForSearchResponse } from '../../../types/geocode';
import {
  IGeocodedForSearchUndergroundData,
  ISuggestionGroup,
  TSuggestionSelectedItem,
} from '../../../types/suggestions';
import { createPolygon } from '../../../utils/geo/createPolygon';

export const requestGeocodedSearchData = async (
  suggest: ISuggestionGroup,
  subdomain?: string,
): Promise<TSuggestionSelectedItem | undefined> => {
  const cachedGeocodeResponse: TApiGeoGeocodeCachedResponse_1 = await getCachedGeocode(
    { request: suggest.displayName },
    subdomain,
  );

  const firstCachedGeocode = cachedGeocodeResponse.items && cachedGeocodeResponse.items[0];

  if (!firstCachedGeocode) {
    return undefined;
  }

  const { text, kind, coordinates, boundedBy } = firstCachedGeocode;

  const searchDataResponse = (await getGeocodedSearchData(
    {
      address: text,
      kind,
      lat: coordinates && coordinates[1],
      lng: coordinates && coordinates[0],
    },
    subdomain,
  )) as IApiGeoGeocodedForSearchResponse;

  if (!searchDataResponse.text) {
    return undefined;
  }

  const result: TSuggestionSelectedItem = {
    data: searchDataResponse as IApiGeoGeocodedForSearchResponse & IGeocodedForSearchUndergroundData,
    dataType: 'geocode',
    polygon: {
      type: 'terms',
      value: createPolygon(boundedBy as number[][]),
    },
    type: 'geo',
  };

  return result;
};
