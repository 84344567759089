import * as React from 'react';
import { clsx } from 'clsx';
import { isNil } from 'ramda';
import { isUndefined } from '@cian/newbuilding-utils';
import { Button, Popover } from '@cian/ui-kit';
import { IconActionChevronRight12 } from '@cian/ui-kit-design-tokens/icons';

import { ClickOutside } from '../outside';
import { Container } from '../Container';
import { SelectionList } from '../SelectionList';

import * as styles from './Select.css';

export interface IVariants {
  id: string;
  label: string;
  isSubtitle?: boolean;
}

export interface ISelectProps {
  text?: string;
  placeholder: string;
  options: Array<IVariants>;
  onChange?(value: string): void;
  value?: string;
}

export interface ISelectState {
  selectedId?: string;
  isOpen?: boolean;
}

export class Select extends React.PureComponent<ISelectProps, ISelectState> {
  public state: Readonly<ISelectState> = {
    selectedId: this.props.value,
    isOpen: false,
  };
  public fakeSelect: HTMLSelectElement;

  public componentDidUpdate(prevProps: Readonly<ISelectProps>): void {
    if (prevProps.value !== this.props.value) {
      this.setState({ selectedId: this.props.value });
    }
  }

  public render() {
    const { text, options, placeholder } = this.props;
    const { selectedId, isOpen } = this.state;
    const selectedVariant = this.getSelectedLabel();

    return (
      <label className={styles['hidden-select-handler']} data-mark="Select" data-style="select_component">
        <select
          onChange={({ currentTarget }) => this.handleChange({ id: currentTarget.value })}
          value={selectedId}
          ref={select => select && (this.fakeSelect = select)}
          className={styles['hidden-select']}
          tabIndex={-1}
        >
          {options.map(({ label, id }) => (
            <option key={id} value={id}>
              {label}
            </option>
          ))}
        </select>

        <Popover
          open={Boolean(isOpen)}
          placement="bottom"
          content={
            <ClickOutside onOutside={this.handleBlur}>
              <Container overflow="hidden" shadow>
                <div className={styles['list']}>
                  <SelectionList options={options} selectedItems={[selectedId]} onClick={this.handleChange} />
                </div>
              </Container>
            </ClickOutside>
          }
        >
          <div>
            <Button
              onClick={this.handleToggle}
              size="XS"
              tabIndex={-1}
              theme="stroke_secondary"
              type="button"
              afterIcon={
                <span className={clsx(styles['arrow_icon'], isOpen && styles['arrow_icon__open'])} aria-hidden>
                  <IconActionChevronRight12 color="icon-main-default" />
                </span>
              }
            >
              {isUndefined(text) ? (selectedVariant ? selectedVariant.label : placeholder) : text}
            </Button>
          </div>
        </Popover>
      </label>
    );
  }

  private handleChange = ({ id }: { id: string; label?: string }) => {
    if (isNil(this.props.value)) {
      this.setState({ selectedId: id });
    }
    if (this.props.onChange) {
      this.props.onChange(id);
    }

    this.handleToggle();
  };

  private handleToggle = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  private handleBlur = () => {
    this.setState({ isOpen: false });
  };

  private getSelectedLabel(): IVariants | undefined {
    return this.props.options.find(({ id }) => id === this.state.selectedId);
  }
}
